import React from "react";
import { useForm } from "react-hook-form";
import { astrologyEndPoint } from "../Services/AllApi";
import toast from "react-hot-toast";
import { apiConnector } from "../Services/ApiConnector";
import astrologyImage from "../Assets/astrologyFront.jpg";
import Footer from "../component/common/Footer";
import MyTextEditor from "../component/common/Quill";
import { Helmet } from "react-helmet-async";
import TextAnimation from "../component/core/Homecomponent/TextAnimation";

const Astrology = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { CREATE_FORM_API } = astrologyEndPoint;
  const onSubmit = async (data) => {
    console.log(data)
    const { fullName, address, phoneNum, dob, timeOfBirth, placeOfBirth } =
      data;
    const toastid = toast.loading("Please wait..");
    try {
      await apiConnector("POST", CREATE_FORM_API, {
        fullName,
        address,
        phoneNum,
        dob,
        timeOfBirth,
        placeOfBirth,
      });
      toast.success("Thank you! Our pandit ji will contact you shortly.");
      setValue("fullName", " ");
      setValue("address", " ");
      setValue("phoneNum", " ");
      setValue("dob", " ");
      setValue("timeOfBirth", " ");
      setValue("placeOfBirth", " ");
    } catch (error) {
      console.log(error);
    }
    toast.dismiss(toastid);
  };
  return (
    <>
     <Helmet>
     <title>Devsthana - Astrology</title>
<meta name="description" content="Unlock the mysteries of the cosmos with Devsthana's astrology services. Our expert astrologers provide personalized insights and guidance based on ancient wisdom and celestial alignments. Whether seeking clarity on relationships, career paths, or life events, discover the profound insights that astrology can offer. Explore our range of astrology services and embark on a journey of self-discovery and enlightenment with Devsthana."/>
<link rel='canonical' href='/astrology' />
<meta property="og:title" content="Devsthana - Astrology" />
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.devsthana.com/astrology" />

    </Helmet>
      <div className=" flex items-center justify-between flex-col min-h-screen mt-20 lg:px-0 px-3">
      <div className="w-full -mt-2">
      <TextAnimation/>

      </div>
        <div className=" max-w-screen-xl mx-auto flex flex-col h-full  ">
          <div className=" flex justify-between w-full items-center h-full lg:flex-row flex-col">
            <p className=" lg:text-2xl text-xl text-center font-bold px-5">
              Astrology is the study of the movements and relative positions of
              celestial objects as a means of divining information about human
              affairs and terrestrial events. Please fill out the form below to
              get personalized astrology insights.
            </p>

            <img
              src={astrologyImage}
              alt="astrology"
              className=" rounded-full lg:w-full h-full object-cover animate-spin w-[300px] "
            />
          </div>

          <div className="w-full flex flex-col items-center justify-center mt-14">
            <form
              className="w-full lg:w-[50%] flex flex-col gap-6 p-8 rounded-xl shadow-2xl bg-white"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 className="text-3xl font-semibold text-center text-orange-500 mb-6">Astrology Consultation Form</h2>
              
              {/* Full Name Field */}
              <div className="flex flex-col gap-2">
                <label className="text-orange-500 font-medium">Enter Full Name <sup className="text-red-500 text-sm">*</sup></label>
                <input
                  type="text"
                  className="w-full border-2 border-orange-500 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-200"
                  placeholder="Enter full name here"
                  {...register("fullName", { required: true })}
                />
                {errors.fullName && <span className="text-red-500 text-sm">Full name is required</span>}
              </div>

              {/* Phone Number Field */}
              <div className="flex flex-col gap-2">
                <label className="text-orange-500 font-medium">Enter Phone Number <sup className="text-red-500 text-sm">*</sup></label>
                <input
                  type="number"
                  placeholder="Whatsapp Number"
                  className="w-full border-2 border-orange-500 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-200"
                  {...register("phoneNum", {
                    required: "Please enter your phone number",
                    maxLength: { value: 10, message: "Invalid phone number" },
                    minLength: { value: 8, message: "Invalid phone number" },
                  })}
                />
                {errors.phoneNum && <span className="text-red-500 text-sm">{errors.phoneNum.message}</span>}
              </div>

              {/* Address Field */}
              <div className="flex flex-col gap-2">
                <label className="text-orange-500 font-medium">Enter Address <sup className="text-red-500 text-sm">(optional)</sup></label>
                <input
                  type="text"
                  className="w-full border-2 border-orange-500 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-200"
                  placeholder="Enter address here"
                  {...register("address")}
                />
              </div>

              {/* Date of Birth Field */}
              <div className="flex flex-col gap-2">
                <label className="text-orange-500 font-medium">Enter Date of Birth <sup className="text-red-500 text-sm">(optional)</sup></label>
                <input
                  type="date"
                  className="w-full border-2 border-orange-500 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-200"
                  {...register("dob")}
                />
              </div>

              {/* Place of Birth Field */}
              <div className="flex flex-col gap-2">
                <label className="text-orange-500 font-medium">Enter Place of Birth <sup className="text-red-500 text-sm">(optional)</sup></label>
                <input
                  type="text"
                  className="w-full border-2 border-orange-500 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-200"
                  {...register("placeOfBirth")}
                  placeholder="Enter place of birth here"
                />
              </div>

              {/* Time of Birth Field */}
              <div className="flex flex-col gap-2">
                <label className="text-orange-500 font-medium">Enter Time of Birth <sup className="text-red-500 text-sm">(optional)</sup></label>
                <input
                  type="time"
                  className="w-full border-2 border-orange-500 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-200"
                  {...register("timeOfBirth")}
                />
              </div>

              {/* Submit Button */}
              <div className="w-full flex justify-center mt-6">
                <button className="bg-gradient-to-r from-orange-500 to-red-500 px-6 py-3 rounded-lg text-white text-lg font-semibold shadow-md hover:scale-105 transition-transform duration-200">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Astrology;